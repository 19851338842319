.footer_Menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .footer_Menu {
        justify-content: space-between;
    }
}